<script setup>
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useToast} from "vue-toast-notification";
import {useRouter} from "vue-router";
import {computed, inject, onBeforeMount, onBeforeUnmount, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import FormTextField from "../../Components/FormTextField.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
import FormDatePicker from "../../Components/FormDatePicker.vue";
import FormSelectField from "../../Components/FormSelectField.vue";
import FormSelectCenter from "../../Components/FormSelectCenter.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import {useAuthUserStore} from "../../Store/AuthUserStore.js";
import FormPhoneField from "../../Components/FormPhoneField.vue";
import FormSelectCountry from "../../Components/FormSelectCountry.vue";

const { httpErrorsHandler } = useHttpErrorsHandler();
const brandConfig = inject('brandConfig');
const authUserStore = useAuthUserStore();
const authUser = computed(() => authUserStore.authUser);
const $toast = useToast();
const router = useRouter();

const loading = ref(false)
const centerPreSelected = ref(null)
const errors = ref(null)
const form = reactive( {
    gender: null,
    first_name: null,
    last_name: null,
    company_name: null,
    email: null,
    phone_number: null,
    phone_number_2: null,
    center_id: null,
    birth_date: null,
    subscribed_to_newsletter: false,
    notes: null,
    last_contact: null,
    preferred_contact_method: null,
    client_status: 'prospect',
    referral_source: 'Comptoir',
    recontact_required: false,
    recontact_reason: null,
    vehicle_type: null,
    car_brand_id: null,
    car_model_id: null,
    car_version_id: null,
    car_engine_id: null,
    address: {
        address_line_1: null,
        address_line_2: null,
        postal_code: null,
        city: null,
        country: null,
    }
})

onBeforeMount(() => {
    if (authUser.value.centers.length >= 1) {
        centerPreSelected.value = {
            data: {
                id: authUser.value.centers[0].id,
                name: authUser.value.centers[0].name
            }
        }
    }
})
function createClient() {
    loading.value = true;
    let formData = new FormData();

    Object.entries(form).forEach(([key, value]) => {
        //for address array, if all data inside are null or empty, pass
        if (key === 'address') {
            if (Object.values(value).every(x => (x === null || x === ''))) {
                return;
            }
            else {
                Object.entries(value).forEach(([itemKey, itemValue]) => {
                    if (itemValue !== null && itemValue !== '') {
                        formData.append(`${key}[${itemKey}]`, itemValue);
                    }
                });
                return;
            }
        }

        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    axios.post("/api/clients", formData)
        .then(response => {
            $toast.success('Client ajouté avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            router.push({name: 'clients.show', params: {id: response.data.data.id}});
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loading.value = false;
        });
}

const carBrands = ref([]);
const carModels = ref([]);
const carVersions = ref([]);
const carEngines = ref([]);
watch(
    () => form.vehicle_type,
    async (newValues, oldValues) => {
        if (newValues !== oldValues) {
            form.car_brand_id = null;
            form.car_model_id = null;
            form.car_version_id = null;
            form.car_engine_id = null;
            carBrands.value = [];
            if (form.vehicle_type !== null){
                await getCarBrands();
            }
        }
    },
    { deep: true }
);

function getCarBrands(){
    carBrands.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/brands?type=' + form.vehicle_type)
            .then(response => {
                response.data.data.forEach(brand => {
                    carBrands.value.push({
                        text: brand.data.name,
                        value: brand.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_brand_id,
    async (newValues, oldValues) => {
        if (newValues !== oldValues) {
            form.car_model_id = null;
            form.car_version_id = null;
            form.car_engine_id = null;
            carModels.value = [];
            if (form.car_brand_id !== null) {
                await getCarModels();
            }
        }
    },
    { deep: true }
);

function getCarModels(){
    carModels.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/models?car_brand_id=' + form.car_brand_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carModels.value.push({
                        text: model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_model_id,
    async (newValues, oldValues) => {
        if (newValues !== oldValues) {
            form.car_version_id = null;
            form.car_engine_id = null;
            carVersions.value = [];
            if (form.car_model_id !== null){
                await getCarVersions();
            }
        }
    },
    { deep: true }
);
function getCarVersions(){
    carVersions.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/versions?car_model_id=' + form.car_model_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carVersions.value.push({
                        text: model.data.name === null ? model.data.from : model.data.from + ' - ' + model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => form.car_version_id,
    async (newValues, oldValues) => {
        if (newValues !== oldValues) {
            carEngines.value = [];
            form.car_engine_id = null;
            if (form.car_version_id !== null){
                await getCarEngines();
            }
        }
    },
    { deep: true }
);

function getCarEngines(){
    carEngines.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning?all=1&car_version_id=' + form.car_version_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carEngines.value.push({
                        text: model.data.power_type_fr + ' - ' + model.data.name + ' - ' + model.data.horsepower + 'ch / ' + model.data.torque + 'Nm',
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

function calculatePrice(type, price) {

    let basePrice = price;
    let priceStage1 = 0;
    let priceStage2 = 0;
    let priceE85 = 0;
    let priceE85plus = 0;

    if (brandConfig.brand === 'rstronic') {
        basePrice = price - 100;
        priceStage1 = Math.max(basePrice, 350);
        priceStage2 = priceStage1 + 100;
        priceE85 = Math.max(priceStage1, 490);
        priceE85plus = Math.max(priceStage1 + 100, 590);

        if (price < 490) {
            priceE85 = 490;
            priceE85plus = 590;
        }
    }

    if (brandConfig.brand === 'shiftech') {
        basePrice = price + 9;
        priceStage1 = Math.max(basePrice, 399);
        priceStage2 = priceStage1 + 200;

        priceE85 = Math.max(priceStage1, 599);
        priceE85plus = Math.max(priceStage1 + 200, 799);

        if (price < 590) {
            priceE85 = 599;
            priceE85plus = 799;
        }
    }

    switch (type) {
        case 'stage1':
            return priceStage1;
        case 'stage2':
            return priceStage2;
        case 'e85':
            return priceE85;
        case 'e85plus':
            return priceE85plus;
        default:
            return price;
    }
}
const activeTab = ref('stage1Tab')
function showModalEngine() {
    getEngineDetails(form.car_engine_id);
    const modaleEngineDetails = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalEngineDetails'))
    modaleEngineDetails.show();
}

const carEngineDetails = ref(null);
const loadingEngineDetails = ref(true);
function getEngineDetails(idApiEngine) {
    loadingEngineDetails.value = true;
    axios.get('/api/chiptuning/id-api/' + idApiEngine)
        .then(response => {
            carEngineDetails.value = response.data;
            loadingEngineDetails.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loading.value = false;
        });
}
</script>

<template>
<div>
    <div class="row">
        <div class="col-lg-12">
            <!--Loader-->
            <div class="card" v-if="loading">
                <div class="card-body">
                    <loader/>
                </div>
            </div>
        </div>
    </div>
    <!--Form-->
    <form v-on:submit.prevent="createClient">
        <div class="row" v-if="!loading">
            <div class="col-lg-6">
                <!--Principal infos-->
                <div class="card">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-account-box me-2 align-middle fs-4"></i>Informations principales</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <form-select-field
                            name="gender"
                            label="Civilité"
                            :label-hidden="false"
                            @update:field="form.gender = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.gender"
                            bs-class="col-xl-2"
                            :disabled="false"
                            :liste-options="[
                                { text: 'M.', value: 'man' },
                                { text: 'Mme', value: 'woman' }
                            ]"
                        />

                        <form-text-field
                            name="first_name"
                            label="Prénom"
                            :label-hidden="false"
                            @update:field="form.first_name = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.first_name"
                            bs-class="col-xl-5"
                        />

                        <form-text-field
                            name="last_name"
                            label="Nom"
                            :label-hidden="false"
                            @update:field="form.last_name = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.last_name"
                            bs-class="col-xl-5"
                        />

                        <form-select-field
                            name="client_status"
                            label="Statut"
                            :label-hidden="false"
                            @update:field="form.client_status = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.client_status"
                            bs-class="col-xl-6"
                            :disabled="false"
                            :liste-options="[
                                { text: 'Autre', value: 'other' },
                                { text: 'Client', value: 'client' },
                                { text: 'Client douteux', value: 'client_doubtful' },
                                { text: 'Fournisseur', value: 'supplier' },
                                { text: 'Prospect', value: 'prospect' },
                                { text: 'Spam', value: 'spam' },
                                { text: 'Opportunité', value: 'opportunity' },
                            ]"
                        />

                        <form-phone-field
                            name="phone_number"
                            label="Téléphone"
                            :label-hidden="false"
                            @update:field="form.phone_number = $event"
                            :errors="errors"
                            :required="true"
                            bs-class="col-xl-6"
                            :data="form.phone_number"
                        />


                        <form-select-center
                            :errors="errors"
                            label="Centre"
                            name="center_id"
                            :required="true"
                            :data="form.center_id"
                            @update:field="form.center_id = ($event !== null ? $event.id : null)"
                            bs-class="col-xl-6"
                            :disabled="false"
                            :taggable="false"
                            :label-hidden="false"
                            :centers-pre-selected="null"
                            placeholder="Sélectionnez un centre"
                            :center-pre-selected="centerPreSelected"
                        />

                        <form-text-field
                            name="email"
                            label="Email"
                            :label-hidden="false"
                            @update:field="form.email = $event"
                            :errors="errors"
                            :required="true"
                            :data="form.email"
                            bs-class="col-xl-6"
                            type="email"
                        />
                        <form-text-field
                            name="company_name"
                            label="Société"
                            :label-hidden="false"
                            @update:field="form.company_name = $event"
                            :errors="errors"
                            :required="false"
                            :data="form.company_name"
                            bs-class="col-xl-6"
                            type="text"
                        />

                        <form-phone-field
                            name="phone_number_2"
                            label="Téléphone secondaire"
                            :label-hidden="false"
                            @update:field="form.phone_number_2 = $event"
                            :errors="errors"
                            :required="false"
                            bs-class="col-xl-6"
                            :data="form.phone_number_2"
                        />
                    </div>
                </div>
            </div>
                <!--Secondary infos-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-information-outline me-2 align-middle fs-4"></i>Informations secondaires</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">

                            <form-select-field
                                name="referral_source"
                                label="Origine du contact"
                                :label-hidden="false"
                                @update:field="form.referral_source = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.referral_source"
                                bs-class="col-xl-12"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'Comptoir', value: 'Comptoir' },
                                    { text: 'Événement', value: 'Événement' },
                                    { text: 'RingOver', value: 'RingOver' },
                                    { text: 'Influenceur', value: 'Influenceur' },
                                    { text: 'Réseaux sociaux', value: 'Réseaux sociaux' },
                                    { text: 'ADS', value: 'ADS' },
                                    { text: 'Site web', value: 'Site web' },
                                    { text: 'Site web - ChatBot', value: 'Site web - ChatBot' },
                                    { text: 'Bouche-à-oreille', value: 'Bouche à oreille' },
                                    { text: 'Démarchage', value: 'Démarchage' },
                                    { text: 'Achat Lead', value: 'Achat Lead' },
                                ]"
                            />

                            <!--<form-select-field-->
                            <!--    name="preferred_contact_method"-->
                            <!--    label="Méthode de contact préférée"-->
                            <!--    :label-hidden="false"-->
                            <!--    @update:field="form.preferred_contact_method = $event"-->
                            <!--    :errors="errors"-->
                            <!--    :required="false"-->
                            <!--    :data="form.preferred_contact_method"-->
                            <!--    bs-class="col-xl-6"-->
                            <!--    :disabled="false"-->
                            <!--    :liste-options="[-->
                            <!--        { text: 'Téléphone', value: 'Téléphone' },-->
                            <!--        { text: 'Email', value: 'Email' },-->
                            <!--        { text: 'SMS', value: 'SMS' }-->
                            <!--    ]"-->
                            <!--/>-->

                            <form-date-picker
                                name="birth_date"
                                label="Date de naissance"
                                :label-hidden="false"
                                @update:field="form.birth_date = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.birth_date"
                                bs-class="col-xl-6"
                                :disabled="false"
                            />

                            <form-date-picker
                                name="last_contact"
                                label="Dernier contact"
                                :label-hidden="false"
                                @update:field="form.last_contact = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.last_contact"
                                bs-class="col-xl-6"
                                :disabled="false"
                            />

                            <form-textarea
                                name="notes"
                                label="Notes"
                                :label-hidden="false"
                                @update:field="form.notes = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.notes"
                                bs-class="col-xl-12"
                                :row="2"
                            />

                            <form-switch
                                name="subscribed_to_newsletter"
                                label="Inscrit à la newsletter ?"
                                :label-hidden="false"
                                @update:field="form.subscribed_to_newsletter = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.subscribed_to_newsletter"
                                bs-class="col-xl-12"
                                :disabled="false"
                            />

                            <form-switch
                                name="recontact_required"
                                label="Client à recontacter ?"
                                :label-hidden="false"
                                @update:field="form.recontact_required = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.recontact_required"
                                bs-class="col-xl-12"
                                :disabled="false"
                            />

                            <form-text-field
                                v-if="form.recontact_required"
                                name="recontact_reason"
                                label="Raison de recontact"
                                :label-hidden="false"
                                @update:field="form.recontact_reason = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.recontact_reason"
                                bs-class="col-xl-12"
                                type="text"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <!--Vehicle-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-car me-2 align-middle fs-4"></i>Véhicule principal</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-select-field
                                name="vehicle_type"
                                label="Type véhicule"
                                :label-hidden="false"
                                @update:field="form.vehicle_type = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.vehicle_type"
                                bs-class="col-xl-6"
                                :disabled="false"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type'  : null"
                                :liste-options="[
                                    { text: 'Voiture', value: 'car' },
                                    { text: 'Agricole', value: 'agri' },
                                    { text: 'ATV', value: 'atv' },
                                    { text: 'Camion', value: 'trucks' },
                                    { text: 'Jetski', value: 'jetski' },
                                    { text: 'Moto', value: 'moto' },
                                    { text: 'Réinitialiser configurateur', value: null },
                                ]"
                            />

                            <form-select-field
                                name="car_brand_id"
                                label="Marques"
                                :label-hidden="false"
                                @update:field="form.car_brand_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.car_brand_id"
                                bs-class="col-xl-6"
                                :liste-options="carBrands"
                                :placeholder="form.vehicle_type === null ? 'Sélectionnez un type' : 'Toutes les marques'"
                                :disabled="form.vehicle_type === null"
                            />

                            <form-select-field
                                name="car_model_id"
                                label="Modèles"
                                :label-hidden="false"
                                @update:field="form.car_model_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.car_model_id"
                                bs-class="col-xl-6"
                                :disabled="form.car_brand_id === null"
                                :liste-options="carModels"
                                :placeholder="form.car_brand_id === null ? 'Sélectionnez une marque' : 'Tous les modèles'"
                            />

                            <form-select-field
                                name="car_version_id"
                                label="Versions"
                                :label-hidden="false"
                                @update:field="form.car_version_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.car_version_id"
                                bs-class="col-xl-6"
                                :disabled="form.car_model_id === null"
                                :liste-options="carVersions"
                                :placeholder="form.car_model_id === null ? 'Sélectionnez un modèle' : 'Toutes les versions'"
                            />

                            <form-select-field
                                name="car_engine_id"
                                label="Motorisation"
                                :label-hidden="false"
                                @update:field="form.car_engine_id = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.car_engine_id"
                                bs-class="col-xl-12"
                                :disabled="form.car_version_id === null"
                                :liste-options="carEngines"
                                :placeholder="form.car_engine_id === null ? 'Sélectionnez un moteur' : 'Tous les moteurs'"
                                :margin="form.car_engine_id === null ? 'mb-3' : 'mb-2'"
                            />
                            <div class="col-md-12" v-if="form.car_engine_id !== null">
                                <button type="button" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light" @click.prevent="showModalEngine()">Fiche moteur</button>
                            </div>

                        </div>
                    </div>
                </div>


                <!--Modal vehicle info-->
                <div id="modalEngineDetails" class="modal fade" tabindex="-1" aria-labelledby="modalEngineDetails" aria-hidden="true" style="display: none;">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="modalEngineDetailsTitle"><i class="mdi mdi-engine-outline fs-22 me-2 align-middle"></i>Fiche moteur</h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                            </div>
                            <div class="modal-body">
                                <loader v-if="loadingEngineDetails" />
                            </div>
                            <div class="modal-body" v-if="!loadingEngineDetails">
                                <div>
                                    <!--Car infos-->
                                    <div>
                                        <div class="mt-0 mx-auto text-center">
                                            <img :src="'/format-image/'+carEngineDetails.data.brand.data.logo+'?w=60&h=60&fm=webp&fit=crop'">
                                        </div>
                                        <div class="mt-3 text-center">
                                            <h4 class="mb-1">{{ carEngineDetails.data.brand.data.name }}</h4>
                                            <p class="text-body fs-15 mb-0">
                                                {{ carEngineDetails.data.model.data.name }}<br>
                                                <span class="text-muted fs-13">
                                                {{ carEngineDetails.data.version.data.name === null ? carEngineDetails.data.version.data.from : carEngineDetails.data.version.data.from + ' - ' + carEngineDetails.data.version.data.name  }}<br>
                                                {{ carEngineDetails.data.power_type_fr }} - {{ carEngineDetails.data.name }}
                                            </span>
                                            </p>
                                        </div>
                                    </div>
                                    <!--Chiptuning-->
                                    <ul class="mt-0 mb-3 nav nav-tabs nav-tabs-custom card-header-tabs border-bottom-0 nav-secondary text-dark" role="tablist">
                                        <li class="nav-item" @click.prevent="activeTab = 'stage1Tab'">
                                            <a class="nav-link stage1" :class="{ 'active': activeTab === 'stage1Tab' }" data-bs-toggle="tab" href="#stage1Tab" role="tab">
                                                Stage 1
                                            </a>
                                        </li>
                                        <li class="nav-item" @click.prevent="activeTab = 'stage2Tab'">
                                            <a class="nav-link stage2" :class="{ 'active': activeTab === 'stage2Tab' }" data-bs-toggle="tab" href="#stage2Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.stage2_available">
                                                Stage 2
                                            </a>
                                        </li>
                                        <li class="nav-item" @click.prevent="activeTab = 'e85Tab'">
                                            <a class="nav-link e85" :class="{ 'active': activeTab === 'e85Tab' }" data-bs-toggle="tab" href="#e85Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85_available">
                                                E85
                                            </a>
                                        </li>
                                        <li class="nav-item" @click.prevent="activeTab = 'e85+Tab'">
                                            <a class="nav-link e85plus" :class="{ 'active': activeTab === 'e85+Tab' }" data-bs-toggle="tab" href="#e85+Tab" role="tab" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85plus_available">
                                                E85+
                                            </a>
                                        </li>
                                        <li class="nav-item" @click.prevent="activeTab = 'dsg'" v-if="carEngineDetails.data.dsg_available">
                                            <a class="nav-link" :class="{ 'active': activeTab === 'dsg' }" data-bs-toggle="tab" href="#dsg" role="tab">
                                                Boite de vitesse
                                            </a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <!--Stage1-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'stage1Tab' }" id="stage1Tab" role="tabpanel">
                                            <p class="text-body mb-3">
                                                La reprogrammation moteur Stage 1 consiste à optimiser la gestion du moteur pour améliorer les performances de votre véhicule et diminuer la consommation de carburant . C'est l'option parfaite pour une meilleure expérience de conduite sans modifications mécaniques.
                                            </p>
                                            <div class="table-responsive" v-if="carEngineDetails.data.developed">
                                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="col-6"></th>
                                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="fw-bold">Origine</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold text-dark">Gain</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ carEngineDetails.data.stage1_horsepower_gain }} ch</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-1 fw-bold">+</span> {{ carEngineDetails.data.stage1_torque_gain }}  Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold stage-1">Stage 1</td>
                                                        <td class="text-center fw-bolder stage-1">{{ carEngineDetails.data.stage1_horsepower_total }} ch</td>
                                                        <td class="text-center fw-bolder stage-1">{{ carEngineDetails.data.stage1_torque_total }} Nm</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">{{ calculatePrice('stage1', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                            <div v-else>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-1"></i> <span class=" fw-bold">Bientôt disponible</span>
                                                </p>
                                            </div>
                                        </div>
                                        <!--Stage2-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'stage2Tab' }" id="stage2Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.stage2_available">
                                            <p class="text-body mb-3">
                                                Le Stage 2 dépasse la simple optimisation logicielle. Il peut être nécessaire d’installer des pièces performances telles qu’un échappement, une admission à haut débit et un échangeur gros volume. Cette préparation est idéale pour les conducteurs en quête d'une augmentation significative de puissance.
                                            </p>
                                            <div class="table-responsive">
                                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="col-6"></th>
                                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="fw-bold">Origine</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold text-dark">Gain</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ carEngineDetails.data.stage2_horsepower_gain }} ch</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="stage-2 fw-bold">+</span> {{ carEngineDetails.data.stage2_torque_gain }}  Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold stage-2">Stage 2</td>
                                                        <td class="text-center fw-bolder stage-2">{{ carEngineDetails.data.stage2_horsepower_total }} ch</td>
                                                        <td class="text-center fw-bolder stage-2">{{ carEngineDetails.data.stage2_torque_total }} Nm</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle stage-2"></i> <span class=" fw-bold">{{ calculatePrice('stage2', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                        </div>
                                        <!--E85-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'e85Tab' }" id="e85Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85_available">
                                            <p class="text-body mb-3">
                                                La conversion E85 Flex-Fuel offre à votre véhicule la flexibilité de rouler au Bioéthanol, à l'essence, ou à un mélange des deux, sans contrainte. Cette adaptation réduit de moitié le coût du carburant et diminue notablement les émissions de CO2, réduisant ainsi l'impact environnemental.
                                            </p>
                                            <div class="table-responsive">
                                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="col-6"></th>
                                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="fw-bold">Origine</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold text-dark">Gain</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;">-</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold e-85">E85</td>
                                                        <td class="text-center fw-bolder e-85">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center fw-bolder e-85">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85"></i> <span class=" fw-bold">{{ calculatePrice('e85', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                        </div>
                                        <!--E85+-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'e85+Tab' }" id="e85+Tab" role="tabpanel" v-if="carEngineDetails.data.developed && carEngineDetails.data.e85plus_available">
                                            <p class="text-body mb-3">
                                                La conversion E85+ Flex-Fuel permet à votre véhicule de fonctionner avec du Bioéthanol, de l'essence, ou un mélange des deux carburants, tout en bénéficiant de l’augmentation de puissance d’un stage 1. En plus de réduire le coût du carburant de moitié, elle diminue aussi les émissions de CO2, tout en améliorant significativement les performances.
                                            </p>
                                            <div class="table-responsive">
                                                <table class="table table-sm table-hover align-middle table-nowrap table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col" class="col-6"></th>
                                                        <th scope="col" class="text-center col-3">PUISSANCE</th>
                                                        <th scope="col" class="text-center col-3">COUPLE</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td class="fw-bold">Origine</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.horsepower }} ch</td>
                                                        <td class="text-center text-body">{{ carEngineDetails.data.torque }} Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold text-dark">Gain</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ carEngineDetails.data.stage1_horsepower_gain }} ch</td>
                                                        <td class="text-center text-dark" style="white-space: nowrap;"><span class="e-85plus fw-bold">+</span> {{ carEngineDetails.data.stage1_torque_gain }}  Nm</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="fw-bold e-85plus">E85+</td>
                                                        <td class="text-center fw-bolder e-85plus">{{ carEngineDetails.data.stage1_horsepower_total }} ch</td>
                                                        <td class="text-center fw-bolder e-85plus">{{ carEngineDetails.data.stage1_torque_total }} Nm</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                                <p class="fs-15">
                                                    <i class="mdi mdi-arrow-right-thin fs-20 align-middle e-85plus"></i> <span class=" fw-bold">{{ calculatePrice('e85plus', carEngineDetails.data.price) }} €</span> <span class="text-muted">TTC</span>
                                                </p>
                                            </div>
                                        </div>
                                        <!--DSG-->
                                        <div class="tab-pane" :class="{ 'active': activeTab === 'dsg' }" id="dsg" role="tabpanel" v-if="carEngineDetails.data.dsg_available">
                                            <p class="text-body mb-3">
                                                La reprogrammation des boîtes automatiques modernes optimise leur potentiel en réduisant le temps de passage des rapports, augmentant le régime et le couple maximaux et améliorant la réactivité.
                                            </p>
                                            <p class="fs-15">
                                                <i class="mdi mdi-arrow-right-thin fs-20 align-middle"></i> <span class=" fw-bold">390 €</span> <span class="text-muted">TTC</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer" v-if="!loadingEngineDetails">
                                <router-link :to="{ name: 'chiptuning.show', params:{ id: carEngineDetails.data.id } }" class="btn btn-secondary bg-gradient waves-effect waves-light" target="_blank">Fiche détaillée</router-link>
                                <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                            </div>

                        </div>
                    </div>
                </div>


                <!--address-->
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-map-marker-outline me-2 align-middle fs-4"></i>Adresse</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <form-text-field
                                name="address.address_line_1"
                                label="Adresse"
                                :label-hidden="false"
                                @update:field="form.address.address_line_1 = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.address.address_line_1"
                                bs-class="col-xl-12"
                            />

                            <form-text-field
                                name="address.address_line_2"
                                label="Complément d'adresse"
                                :label-hidden="false"
                                @update:field="form.address.address_line_2 = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.address.address_line_2"
                                bs-class="col-xl-12"
                            />

                            <form-text-field
                                name="address.postal_code"
                                label="Code postal"
                                :label-hidden="false"
                                @update:field="form.address.postal_code = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.address.postal_code"
                                bs-class="col-xl-4"
                            />

                            <form-text-field
                                name="address.city"
                                label="Ville"
                                :label-hidden="false"
                                @update:field="form.address.city = $event"
                                :errors="errors"
                                :required="false"
                                :data="form.address.city"
                                bs-class="col-xl-4"
                            />

                            <form-select-country
                                name="address.country"
                                label="Pays"
                                :label-hidden="false"
                                @update:field="form.address.country = $event"
                                :errors="errors"
                                :required="true"
                                :data="form.address.country"
                                bs-class="col-xl-4"
                            />
                            <div class="d-flex gap-2 justify-content-end">
                                <button type="button" class="btn btn-soft-dark" @click="router.back()">Retour</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</template>

<style scoped>

</style>
