<script setup>
import FullCalendar from '@fullcalendar/vue3'
import frLocale from '@fullcalendar/core/locales/fr';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from "@fullcalendar/list";
import timeGridPlugin from "@fullcalendar/timegrid";
import {useHttpErrorsHandler} from "../../Composables/httpErrorsHandler.js";
import {useRouter} from "vue-router";
import {inject, nextTick, onBeforeMount, onBeforeUnmount, onMounted, reactive, ref, watch} from "vue";
import Loader from "../../Components/Loader.vue";
import {useToast} from "vue-toast-notification";
import Chat from "../../Components/Chat.vue";
import FormTextarea from "../../Components/FormTextarea.vue";
import FormMultiSelect from "../../Components/FormMultiSelect.vue";
import axios from "axios";
const $toast = useToast();
const brandConfig = inject('brandConfig');
const { httpErrorsHandler } = useHttpErrorsHandler();
const router = useRouter();
const loading = ref(true)
const errors = ref(null)
const clientRequest = ref(null)
import { DocusealForm } from '@docuseal/vue'
import FormSelectField from "../../Components/FormSelectField.vue";
import FormSwitch from "../../Components/FormSwitch.vue";
import FormDateTimePicker from "../../Components/FormDateTimePicker.vue";
import GoogleCalendarColorSelector from "../../Components/GoogleCalendarColorSelector.vue";

function getClientRequest(clientRequestId) {
    loading.value = true;
    axios.get("/api/client-requests/" + clientRequestId)
        .then(async response => {
            clientRequest.value = response.data;
            internalNotes.value = response.data.data.notes;
            loading.value = false;
            //Populate the configurator
            if (response.data.data.vehicle_type !== null) {
                formClientRequestUpdate.vehicle_type = response.data.data.vehicle_type;
                await getCarBrands();
            }
            if (response.data.data.car_brand_id !== null) {
                formClientRequestUpdate.car_brand_id = response.data.data.car_brand_id;
                await getCarModels();
            }
            if (response.data.data.car_model_id !== null) {
                formClientRequestUpdate.car_model_id = response.data.data.car_model_id;
                await getCarVersions();
            }
            if (response.data.data.car_version_id !== null) {
                formClientRequestUpdate.car_version_id = response.data.data.car_version_id;
                formClientRequestUpdate.car_engine_id = response.data.data.car_engine_id;
                await getCarEngines();
            }
            if (response.data.data.appointment !== null) {
                formAppointment.start_date = response.data.data.appointment.data.start_date_en;
                formAppointment.end_date = response.data.data.appointment.data.end_date_en;
                preselectedColor.value = response.data.data.appointment.data.google_color_id;
                formAppointment.google_color_id = response.data.data.appointment.data.google_color_id;

            }
            Object.entries(response.data.data).forEach(([key, value]) => {
                if (key === 'tags') {
                    tagsPreSelected.value = value;
                    return;
                }
                if (Object.hasOwn(formClientRequestUpdate, key) && key !== 'address') {
                    formClientRequestUpdate[key] = value;
                }
            });
            resetNotificationNumber();
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

onBeforeMount(() => {
    getClientRequest(router.currentRoute.value.params.id)
})

function changeStatus(status) {
    if (status === clientRequest.value.data.status) {
        return;
    }
    if (status === 'lost') {
        return showModalReasonForLoss();
    }
    axios.patch("/api/client-requests/" + clientRequest.value.data.id + "/status", { status: status })
        .then(response => {
            clientRequest.value = response.data;
            $toast.success('Statut mis à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function resetNotificationNumber() {
    if (clientRequest.value.data.notifications_center > 0){
        axios.patch("/api/client-requests/" + router.currentRoute.value.params.id + "/reset-notification-number")
            .then(response => {
                clientRequest.value.data.notifications_center = 0;
            })
            .catch(error => {
                httpErrorsHandler(error);
            });
    }
}

//Hide modal if leave page
onBeforeUnmount(() => {
    document.querySelectorAll('.modal').forEach(modalElement => {
        const modalInstance = bootstrap.Modal.getInstance(modalElement);
        if (modalInstance) {
            modalInstance.hide();
        }
    });
})

const clientRequestStatuses = reactive([
    { value: 'pending', label: 'À traiter', badgeColor: 'danger' },
    { value: 'responded', label: 'Répondu', badgeColor: 'secondary' },
    { value: 'awaiting_information', label: 'Attente d\'information', badgeColor: 'info' },
    { value: 'appointment_scheduled', label: 'RDV programmé', badgeColor: 'success' },
    { value: 'appointment_ongoing', label: 'RDV en cours', badgeColor: 'success' },
    { value: 'won', label: 'Gagné', badgeColor: 'success' },
    { value: 'no_show', label: 'Client non présenté', badgeColor: 'dark' },
    { value: 'lost', label: 'Perdu', badgeColor: 'dark' },
    { value: 'closed', label: 'Fermé', badgeColor: 'dark' },
]);

function showModalInternalNotes() {
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalInternalNotes'))
    modal.show();
}
const internalNotes = ref(null)
function updateInternalNotes() {
    axios.patch("/api/client-requests/" + clientRequest.value.data.id + "/notes", { notes: internalNotes.value })
        .then(response => {
            clientRequest.value.data.notes = response.data.data.notes;
            $toast.success('Note interne mise à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalInternalNotes'))
            modal.hide();
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const formPdf = reactive({
    "prestations": [],
    "options": [],
})
const generatingPdf = ref(false)
function generatePdf() {
    generatingPdf.value = true;
    let formData = new FormData();
    Object.entries(formPdf).forEach(([key, value]) => {
        return value.forEach((item) => {
            formData.append(`${key}[]`, item.id);
        });
    });

    axios.post("/api/client-requests/" + clientRequest.value.data.id + "/pdf", formData)
        .then(response => {
            $toast.success('Fiche de travail générée', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            clientRequest.value.data.worksheet_path = response.data.data.worksheet_path;
            clientRequest.value.data.worksheet_generated_at = response.data.data.worksheet_generated_at;
            generatingPdf.value = false;
            window.open(response.data.data.worksheet_path, '_blank');
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalPdf'))
            modal.hide();
            formPdf.prestations = [];
            formPdf.options = [];
        })
        .catch(error => {
            httpErrorsHandler(error);
            generatingPdf.value = false;
        });
}

function showModalPdf() {
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalPdf'))
    modal.show();
}

function canGeneratePdf() {
    if (clientRequest.value.data.car_brand_id === null
        || clientRequest.value.data.car_model_id === null
        || clientRequest.value.data.car_version_id === null
        || clientRequest.value.data.car_engine_id === null
        || clientRequest.value.data.vin === null
        || clientRequest.value.data.license_plate === null
        || clientRequest.value.data.vehicle_year === null
        || clientRequest.value.data.mileage === null) {
        return false;
    }

    if (clientRequest.value.data.car_power_type === 'petrol' || clientRequest.value.data.car_power_type === 'hybrid_petrol') {
        if (clientRequest.value.data.spark_plug_mileage === null
            || clientRequest.value.data.coil_mileage === null
            || clientRequest.value.data.octane_rating === null) {
            return false;
        }
    }

    return true;
}

const slugForm = ref(null)
const loadingSignature = ref(false)
function generateSubmission() {
    loadingSignature.value = true;
    axios.get("/api/client-requests/" + clientRequest.value.data.id + "/initiate-signature")
        .then(response => {
            //close modal pdf
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalPdf'))
            modal.hide();
            const modalSignature = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalSignature'))
            modalSignature.show();
            slugForm.value = response.data.slug;
            loadingSignature.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
            loadingSignature.value = false;
        });
}

function signatureCompleted() {
    const modalSignature = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalSignature'))
    modalSignature.hide();
    $toast.success('Fiche de travail signée. Un email avec la fiche va être envoyé.', {
        position: 'top',
        duration: 5000,
        dismissible: true,
        pauseOnHover: true,
    });
}

onMounted(() => {
    Echo.channel(`client_requests.${router.currentRoute.value.params.id}`)
        .listen('ClientRequestSignedEvent', (e) => {
            clientRequest.value.data.worksheet_signed_path = e.data.data.worksheet_signed_path;
            clientRequest.value.data.worksheet_audit_path = e.data.data.worksheet_audit_path;
            $toast.info('Documents signés disponibles en téléchargement', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        });
});

const showOriginReset = ref(false)
watch(() => formPdf.prestations, () => {
    showOriginReset.value = formPdf.prestations.some(option => option.id === 'ORIGIN_RESET');
})

const formReasonForLoss = reactive({
    "reason_for_loss": null,
})

function showModalReasonForLoss() {
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalReasonForLoss'))
    modal.show();
}

const loadingReasonForLoss = ref(false)
function updateReasonForLoss() {
    loadingReasonForLoss.value = true;
    axios.patch("/api/client-requests/" + clientRequest.value.data.id + "/reason-for-loss", { reason_for_loss: formReasonForLoss.reason_for_loss })
        .then(response => {
            clientRequest.value = response.data;
            $toast.success('Statut et raison de la perte mise à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalReasonForLoss'))
            modal.hide();
            formReasonForLoss.reason_for_loss = null;
            loadingReasonForLoss.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

const loadingClientRequestUpdate = ref(false)
const formClientRequestUpdate = reactive({
    client_id: null,
    center_id: null,
    status: null,
    reason_for_loss: null,
    tags: [],
    car_brand_id: null,
    car_model_id: null,
    car_version_id: null,
    car_engine_id: null,
    car_stage: null,
    contact_reason: null,
    vehicle_type: null,
    notes: null,
    is_on_promotion: false,
    request_origin: null,
    vin: null,
    license_plate: null,
    vehicle_year: null,
    mileage: null,
    spark_plug_mileage: null,
    coil_mileage: null,
    octane_rating: null,
    price: null,
    currency: null
})
const tagsPreSelected = ref([])
const isInitialized = ref(true)
const carBrands = ref([]);
const carModels = ref([]);
const carVersions = ref([]);
const carEngines = ref([]);
const showEngineDetails = ref(false);
watch(
    () => formClientRequestUpdate.vehicle_type,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            formClientRequestUpdate.car_brand_id = null;
            formClientRequestUpdate.car_model_id = null;
            formClientRequestUpdate.car_version_id = null;
            formClientRequestUpdate.car_engine_id = null;
            carBrands.value = [];
            if (formClientRequestUpdate.vehicle_type !== null){
                await getCarBrands();
            }
        }
    },
    { deep: true }
);

function getCarBrands(){
    carBrands.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/brands?type=' + formClientRequestUpdate.vehicle_type)
            .then(response => {
                response.data.data.forEach(brand => {
                    carBrands.value.push({
                        text: brand.data.name,
                        value: brand.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => formClientRequestUpdate.car_brand_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            formClientRequestUpdate.car_model_id = null;
            formClientRequestUpdate.car_version_id = null;
            formClientRequestUpdate.car_engine_id = null;
            carModels.value = [];
            if (formClientRequestUpdate.car_brand_id !== null) {
                await getCarModels();
            }
        }
    },
    { deep: true }
);

function getCarModels(){
    carModels.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/models?car_brand_id=' + formClientRequestUpdate.car_brand_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carModels.value.push({
                        text: model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => formClientRequestUpdate.car_model_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            formClientRequestUpdate.car_version_id = null;
            formClientRequestUpdate.car_engine_id = null;
            carVersions.value = [];
            if (formClientRequestUpdate.car_model_id !== null){
                await getCarVersions();
            }
        }
    },
    { deep: true }
);
function getCarVersions(){
    carVersions.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning/versions?car_model_id=' + formClientRequestUpdate.car_model_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carVersions.value.push({
                        text: model.data.name === null ? model.data.from : model.data.from + ' - ' + model.data.name,
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => formClientRequestUpdate.car_version_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            carEngines.value = [];
            formClientRequestUpdate.car_engine_id = null;
            if (formClientRequestUpdate.car_version_id !== null){
                await getCarEngines();
            }
        }
    },
    { deep: true }
);

function getCarEngines(){
    carEngines.value = [];
    return new Promise((resolve, reject) => {
        axios.get('/api/chiptuning?all=1&car_version_id=' + formClientRequestUpdate.car_version_id)
            .then(response => {
                response.data.data.forEach(model => {
                    carEngines.value.push({
                        text: model.data.power_type_fr + ' - ' + model.data.name + ' - ' + model.data.horsepower + 'ch / ' + model.data.torque + 'Nm',
                        value: model.data.id_api
                    })
                })
                resolve();
            })
            .catch(error => {
                httpErrorsHandler(error);
                reject(error);
            });
    });
}

watch(
    () => formClientRequestUpdate.car_engine_id,
    async (newValues, oldValues) => {
        if (isInitialized.value && newValues !== oldValues) {
            if (showEngineDetails.value === true){
                showEngineDetails.value = false;
            }
        }
    },
    { deep: true }
);

function showModalClientRequestUpdate() {
    const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalUpdateClientRequest'))
    modal.show();
}

function updateClientRequest() {

    loadingClientRequestUpdate.value = true;
    let formData = new FormData();

    Object.entries(formClientRequestUpdate).forEach(([key, value]) => {
        if (typeof value === 'boolean') {
            return formData.append(key, value ? 1 : 0);
        }
        if (key === 'tags') {
            return value.forEach((tag) => {
                formData.append(`${key}[]`, tag.id);
            });
        }
        if (value !== null && value !== '') {
            formData.append(key, value);
        }
    });

    formData.append('_method', 'PUT');
    axios.post("/api/client-requests/" + router.currentRoute.value.params.id, formData)
        .then(response => {
            clientRequest.value = response.data;
            $toast.success('Demande client modifiés avec succès', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            loadingClientRequestUpdate.value = false;
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalUpdateClientRequest'))
            modal.hide();
            Object.entries(response.data.data).forEach(([key, value]) => {
                if (key === 'tags') {
                    tagsPreSelected.value = value;
                }
            });
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
            loadingClientRequestUpdate.value = false;
        });
}

const loadingCalendar = ref(true)
const displayCalendar = ref(false);
function showModalCalendar(event, modalId) {
    eventToShow.value = event;
    const modalElement = document.getElementById(modalId);
    const modalCalendar = bootstrap.Modal.getOrCreateInstance(modalElement);

    const showCalendar = () => {
        displayCalendar.value = true;
        loadingCalendar.value = false;
    };

    const onModalShown = function (e) {
        if (e.target.id === modalId) {
            showCalendar();
            document.removeEventListener('shown.bs.modal', onModalShown);
        }
    };
    document.addEventListener('shown.bs.modal', onModalShown);
    modalCalendar.show();
}


const calendarRef = ref(null)

function getEvents(startDate = null, endDate = null) {
    startDateSave.value = startDate;
    endDateSave.value = endDate;

    const start = new Date(startDate);
    start.setMonth(start.getMonth() - 2);
    const end = new Date(endDate);
    end.setMonth(end.getMonth() + 2);

    axios.get('/api/events', { params: {
            start_date: start,
            end_date: end,
            show_appointments_events: true,
            centers_id: [clientRequest.value.data.center_id]
        } })
        .then(response => {
            events.value.splice(0, events.value.length);
            response.data.data.forEach(event => {
                events.value.push({
                    id: event.data.id,
                    title: event.data.title,
                    start: event.data.start_date,
                    end: event.data.all_day ? event.data.end_date_all_day : event.data.end_date,
                    backgroundColor: event.data.color,
                    allDay: event.data.all_day,
                    location: event.data.location,
                    data : event.data,
                    can : event.can,
                    center: event.data.center_id === null ? null : event.data.center.data.name
                });
            });
            addOrUpdateCurrentEvent();
            loading.value = false;
        })
        .catch(error => {
            httpErrorsHandler(error);
        });
}

function addOrUpdateCurrentEvent() {
    const idEvent = clientRequest.value.data.appointment === null ? clientRequest.value.data.id : clientRequest.value.data.appointment.data.id;
    const index = events.value.findIndex(e => e.id === idEvent);
    if (index !== -1) {
        events.value.splice(index, 1);
    }
    if (formAppointment.start_date && formAppointment.end_date) {
        events.value.push({
            id: idEvent,
            title: getEventTitle(),
            start: parseDate(formAppointment.start_date),
            end: parseDate(formAppointment.end_date),
            allDay: false,
            backgroundColor: getColorFromGoogleCalendarId(formAppointment.google_color_id),
            extendedProps: {
                customProp: 'value'
            }
        });
    }
}

const formAppointment = reactive({
    start_date: null,
    end_date: null,
    google_color_id: 7,
})
const events = ref([]);
const preselectedColor = ref(null);
const startDateSave =  ref(null);
const endDateSave = ref(null);
const eventToShow = ref(null);
const calendarOptions = {
    timeZone: 'local',
    themeSystem: 'bootstrap',
    initialView: 'timeGridThreeDay',
    plugins: [dayGridPlugin, listPlugin, timeGridPlugin],
    weekends: true,
    firstDay: 1,
    headerToolbar: {
        start: 'today prev,next',
        center: 'title',
        end: 'timeGridWeek,timeGridThreeDay,timeGridDay,listMonth'
    },
    eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    },
    events: events.value,
    expandRows: true,
    displayEventTime: true,
    locale: frLocale,
    eventColor: '#ffffff',
    scrollTime: '08:00:00',
    height: '100%',
    eventOverlap: false,
    slotEventOverlap: false,
    allDaySlot : false,
    slotMinTime: '07:00:00',
    slotMaxTime: '20:00:00',
    datesSet: async (dateInfo) => {
        getEvents(dateInfo.startStr, dateInfo.endStr);
    },
    eventContent: function(arg) {
        let titleWithCenter = arg.event.extendedProps.center ? arg.event.title + ' - ' + arg.event.extendedProps.center : arg.event.title;

        if (arg.view.type === 'listMonth') {
            return { html: titleWithCenter };
        } else {
            return { html: arg.event.title };
        }
    },
    views: {
        timeGridThreeDay: {
            type: 'timeGrid',
            duration: { days: 3 },
            buttonText: '3 Jours'
        },
    }
}

watch(() => formAppointment.start_date,
    (newStartDate) => {
        if (!newStartDate) return;
        if (calendarRef.value === null) return;

        const calendarApi = calendarRef.value.getApi();
        calendarApi.gotoDate(newStartDate);

        const startDate = parseDate(newStartDate);
        let endDate = new Date(startDate.getTime());
        endDate.setHours(startDate.getHours() + 2);
        formAppointment.end_date = formatDate(endDate, true);
    }
);

watch(() => formAppointment.end_date,
    (newEndDate) => {
        if (!newEndDate) return;
        if (calendarRef.value === null) return;
        const startDate = parseDate(formAppointment.start_date);
        const endDate = parseDate(newEndDate);

        if (startDate >= endDate) {
            endDate.setHours(startDate.getHours() + 1);
            formAppointment.end_date = formatDate(endDate, true);
            $toast.warning('La date de fin ne peut pas être antérieure à la date de début.', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
        }
        addOrUpdateCurrentEvent();
    }
);
watch(() => formAppointment.google_color_id,
    () => {
        if (calendarRef.value === null) return;
        addOrUpdateCurrentEvent();
    }
);
function parseDate(dateStr) {
    const parts = dateStr.split(' ');
    const datePart = parts[0];
    const timePart = parts.length > 1 ? parts[1] : null;

    const [year, month, day] = datePart.split('-');
    if (timePart) {
        const [hours, minutes] = timePart.split(':');
        return new Date(year, month - 1, day, hours, minutes);
    } else {
        return new Date(year, month - 1, day);
    }
}

function formatDate(date, includeTime = true) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    if (includeTime) {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    } else {
        return `${year}-${month}-${day}`;
    }
}

const loadingSetAppointment = ref(false)
function setAppointmentDate() {
    loadingSetAppointment.value = true;
    axios.patch("/api/client-requests/" + clientRequest.value.data.id + "/appointment", formAppointment).
        then(response => {
            clientRequest.value.data.appointment = response.data.data.appointment;
            clientRequest.value.data.status = response.data.data.status;
            clientRequest.value.data.status_label_fr = response.data.data.status_label_fr;
            clientRequest.value.data.status_badge_color = response.data.data.status_badge_color;
            $toast.success('RDV mis à jour', {
                position: 'top',
                duration: 5000,
                dismissible: true,
                pauseOnHover: true,
            });
            const modal = bootstrap.Modal.getOrCreateInstance(document.getElementById('modalCalendar'))
            modal.hide();
            loadingSetAppointment.value = false;
        })
        .catch(error => {
            errors.value = error.response.data.errors;
            httpErrorsHandler(error);
        });
}

function getColorFromGoogleCalendarId(colorId) {
    const colors = {
        1: '#7986CB',
        2: '#33B679',
        3: '#8E24AA',
        4: '#E67C73',
        5: '#F6BF26',
        6: '#F4511E',
        7: '#039BE5',
        8: '#616161',
        9: '#3F51B5',
        10: '#0B8043',
        11: '#D50000',
    };

    return colors[colorId] || '#7986CB';
}

function getEventTitle() {
    let title = '';
    if (clientRequest.value.data.car_engine_id !== null) {
        title = clientRequest.value.data.car_brand + ' ' + clientRequest.value.data.car_model + ' - ' + clientRequest.value.data.car_version + ' ' + clientRequest.value.data.car_engine + ' - #' + clientRequest.value.data.id;
    } else {
        title = clientRequest.value.data.client.data.first_name + ' ' + clientRequest.value.data.client.data.last_name + ' - #' + clientRequest.value.data.id;
    }
    return title;
}
</script>

<template>
    <div class="row">
        <div class="col-12" v-show="loading">
            <loader />
        </div>
        <div class="col-xl-6" v-if="!loading">
            <div class="card border">
                <div class="card-header align-items-center d-flex">
                    <h4 class="card-title mb-0 flex-grow-1">
                        <i class="mdi me-2 align-middle fs-4" :class="router.currentRoute.value.name === 'client-request-others.show' ? 'mdi-folder-information-outline' : 'mdi-car-info'"></i>
                        {{ router.currentRoute.value.name === 'client-request-others.show' ? 'Autre demande' : 'Demande client' }}
                    </h4>
                    <div class="avatar-xs me-2">
                        <a href="#" class="avatar-title bg-success-subtle text-success fs-16 align-middle rounded" title="RDV Client" @click.prevent="showModalCalendar(event, 'modalCalendar');">
                            <i class="ri-calendar-check-fill"></i>
                        </a>
                    </div>
                    <div class="avatar-xs me-2">
                        <a href="#" class="avatar-title bg-dark-subtle text-dark fs-15 rounded" title="Fiche de travail" @click.prevent="showModalPdf">
                            <i class="ri-file-edit-fill"></i>
                        </a>
                    </div>
                    <div class="avatar-xs me-2">
                        <a href="#" class="avatar-title bg-warning-subtle text-warning fs-15 rounded" title="Notes" @click.prevent="showModalInternalNotes">
                            <i class="ri-sticky-note-fill"></i>
                        </a>
                    </div>
                    <div class="avatar-xs">
                        <router-link :to="{ name: 'client-requests.edit', params:{ id: clientRequest.data.id } }" class="avatar-title bg-secondary-subtle text-secondary fs-15 rounded" title="Modifier">
                            <i class="ri-edit-2-fill"></i>
                        </router-link>
                    </div>

                </div>
                <div class="card-body">

                    <div class="table-responsive">
                        <table class="table table-md table-nowrap mb-3">
                            <tbody>
                            <tr>
                                <th scope="row" class="col-5 border-top-0">ID</th>
                                <td class="border-top-0 text-body fw-semibold">#{{ clientRequest.data.id }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Centre</th>
                                <td>{{ clientRequest.data.center.data.name }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Statut</th>
                                <td>
                                    <span :class="'fs-11 badge bg-'+clientRequest.data.status_badge_color+'-subtle  text-'+clientRequest.data.status_badge_color">{{ clientRequest.data.status_label_fr }}</span>
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info ms-1" id="statusDropdown" data-bs-toggle="dropdown" v-if="clientRequest.can.update"></a>
                                    <ul class="dropdown-menu" aria-labelledby="statusDropdown">
                                        <li v-for="(status, key) in clientRequestStatuses" :key="key">
                                            <a class="dropdown-item" href="#" @click.prevent="changeStatus(status.value)">
                                                <span :class="`fs-11 badge bg-${status.badgeColor}-subtle text-${status.badgeColor}`">{{ status.label }}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.status === 'lost'">
                                <th scope="row" class="col-5">Raison perte</th>
                                <td class="align-middle">
                                   {{ clientRequest.data.reason_for_loss }}
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info" @click.prevent="showModalClientRequestUpdate" v-if="clientRequest.can.update"></a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Client</th>
                                <td class="align-middle">
                                    <router-link :to="{ name: 'clients.show', params: { id: clientRequest.data.client.data.id }}" class="mr-1 align-middle text-secondary" title="Afficher la fiche client">
                                        {{ clientRequest.data.client.data.last_name }} {{ clientRequest.data.client.data.first_name }}
                                    </router-link>
                                    <span :class="'fs-11 align-middle ms-2 badge bg-'+clientRequest.data.client.data.client_status_badge_color+'-subtle  text-'+clientRequest.data.client.data.client_status_badge_color">{{ clientRequest.data.client.data.client_status_label_fr }}</span>
                                    <router-link :to="{ name: 'clients.edit', params:{ id: clientRequest.data.client.data.id } }" class="text-info align-middle" title="Modifier le client" v-if="clientRequest.data.client.can.update">
                                        <i class="ri-pencil-fill fs-17 px-1 align-middle"></i>
                                    </router-link>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Téléphone</th>
                                <td>{{ clientRequest.data.client.data.phone_number }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Email</th>
                                <td>{{ clientRequest.data.client.data.email }}</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Raisons contact</th>
                                <td>
                                    {{ clientRequest.data.contact_reason }}
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info" @click.prevent="showModalClientRequestUpdate" v-if="clientRequest.can.update"></a>
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.vehicle_type_label_fr !== null || clientRequest.data.car_power_type_label_fr !== null">
                                <th scope="row" class="col-5">Véhicule - Type</th>
                                <td>
                                    {{ clientRequest.data.vehicle_type_label_fr }} - {{ clientRequest.data.car_power_type_label_fr }}
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info" @click.prevent="showModalClientRequestUpdate" v-if="clientRequest.can.update"></a>
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.car_brand !== null || clientRequest.data.car_model !== null">
                                <th scope="row" class="col-5">Marque - Modèle</th>
                                <td>
                                    {{ clientRequest.data.car_brand }} - {{ clientRequest.data.car_model }}
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info" @click.prevent="showModalClientRequestUpdate" v-if="clientRequest.can.update"></a>
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.car_version !== null || clientRequest.data.car_engine !== null">
                                <th scope="row" class="col-5 align-middle">Version - Moteur</th>
                                <td>
                                    <span class="align-middle">{{ clientRequest.data.car_version }} - {{ clientRequest.data.car_engine }}</span>
                                    <router-link :to="{ name: 'chiptuning.show', params:{ id: clientRequest.data.engine.data.id } }" target="_blank" class="ms-2 btn btn-sm btn-dark bg-gradient bg-gradient waves-effect waves-light btn-sm" v-if="clientRequest.data.engine !== null">
                                        Fiche moteur
                                    </router-link>
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info" @click.prevent="showModalClientRequestUpdate" v-if="clientRequest.can.update"></a>
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.vin !== null">
                                <th scope="row" class="col-5">VIN</th>
                                <td>
                                    {{ clientRequest.data.vin }}
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.license_plate !== null">
                                <th scope="row" class="col-5">Immatriculation</th>
                                <td>{{ clientRequest.data.license_plate }}</td>
                            </tr>
                            <tr v-if="clientRequest.data.vehicle_year !== null">
                                <th scope="row" class="col-5">Année du véhicule</th>
                                <td>{{ clientRequest.data.vehicle_year }}</td>
                            </tr>
                            <tr v-if="clientRequest.data.mileage !== null">
                                <th scope="row" class="col-5">Kilométrage</th>
                                <td>{{ clientRequest.data.mileage_formatted }} km</td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Prestation</th>
                                <td>
                                    {{ clientRequest.data.car_stage === null ? '-' : clientRequest.data.car_stage }}
                                    <a href="#" class="ri-pencil-fill fs-17 px-1 align-middle text-info" @click.prevent="showModalClientRequestUpdate" v-if="clientRequest.can.update"></a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Tags</th>
                                <td>
                                    <div class="d-flex align-items-center flex-wrap gap-2">
                                        <span v-for="(tag, tagIndex) in clientRequest.data.tags" :key="tagIndex" class="badge bg-primary-subtle text-primary fs-11 my-badge">{{ tag }}</span>
                                        <a href="#" class="ri-pencil-fill fs-17 text-info" @click.prevent="showModalClientRequestUpdate" v-if="clientRequest.can.update"></a>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5">Promotion ?</th>
                                <td>
                                    <span class="fs-11 badge bg-success-subtle text-success" v-if="clientRequest.data.is_on_promotion">Oui</span>
                                    <span class="fs-11 badge bg-danger-subtle text-danger" v-else>Non</span>
                                </td>
                            </tr>
                            <tr v-if="clientRequest.data.price !== null">
                                <th scope="row" class="col-5">Prix</th>
                                <td>{{ clientRequest.data.price_formatted }} €</td>
                            </tr>
                            <tr v-if="clientRequest.data.website_page !== null">
                                <th scope="row" class="col-5">Dernière page visitée</th>
                                <td><a :href="clientRequest.data.website_page" target="_blank" class="text-secondary">Lien site web</a></td>
                            </tr>
                            <tr>
                                <th scope="row" class="col-5 border-bottom-0">Date création</th>
                                <td class="border-bottom-0">{{ clientRequest.data.created_at_fr }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="alert alert-success alert-label-icon rounded-label shadow border" role="alert" v-if="clientRequest.data.appointment !== null">
                            <i class="ri-calendar-check-fill label-icon"></i>
                        <strong>Rendez-vous :</strong><br>
                        <p class="mb-0">
                            Le {{ clientRequest.data.appointment.data.start_end_date_fr }} à {{ clientRequest.data.appointment.data.start_end_hour_fr }}<br>
                        </p>
                    </div>
                    <div class="alert alert-secondary alert-label-icon rounded-label shadow border" role="alert">
                        <i class="ri-chat-quote-line label-icon"></i>
                        <strong>Commentaire client : </strong><br>
                        <p v-html="clientRequest.data.client_comment !== null ? clientRequest.data.client_comment.replace(/\n/g, '<br />') : 'Aucun commentaire.'" class="mb-0"></p>
                    </div>
                    <div class="alert alert-warning alert-label-icon rounded-label shadow" role="alert" >
                        <a href="#" class="ri-sticky-note-fill label-icon" @click.prevent="showModalInternalNotes"></a>
                        <strong>Notes internes : </strong><br>
                        <p  v-if="clientRequest.data.notes !== null" v-html="clientRequest.data.notes.replace(/\n/g, '<br />')" class="mb-0"></p>
                        <p  class="mb-0" v-else>--</p>
                    </div>

                </div>
            </div>
            <div class="card" v-if="clientRequest.data.attachments.length > 0">
                <div class="card-header align-items-center d-flex border-bottom-dashed">
                    <h4 class="card-title mb-0 flex-grow-1"><i class="mdi mdi-attachment me-2 align-middle fs-4"></i>Fichiers joints</h4>
                </div>

                <div class="card-body">

                    <div class="vstack gap-2">

                        <div class="border rounded border-dashed p-2" v-for="(attachment, index) in clientRequest.data.attachments" :key="index">
                            <div class="d-flex align-items-center">
                                <div class="flex-shrink-0 me-3">
                                    <div class="avatar-sm">
                                        <div class="avatar-title bg-light text-secondary rounded fs-24 shadow">
                                            <!--Icon depending mime type-->
                                            <i class="ri-image-2-line" v-if="attachment.data.mime_type.includes('image')"></i>
                                            <i class="ri-video-line" v-else-if="attachment.data.mime_type.includes('video')"></i>
                                            <i class="ri-folder-music-line" v-else-if="attachment.data.mime_type.includes('audio')"></i>
                                            <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('zip')"></i>
                                            <i class="ri-folder-zip-line" v-else-if="attachment.data.mime_type.includes('pdf')"></i>
                                            <i class="ri-file-text-line" v-else></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                    <h5 class="fs-13 mb-1"><a :href="attachment.data.path" target="_blank" class="text-body text-truncate d-block">{{ attachment.data.name }}</a></h5>
                                    <div>{{ attachment.data.size }}</div>
                                </div>
                                <div class="flex-shrink-0 ms-2">
                                    <div class="d-flex gap-1">
                                        <a :href="attachment.data.path" target="_blank" class="btn btn-icon text-muted btn-sm fs-18 shadow-none"><i class="ri-download-2-line"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal notes -->
            <div id="modalInternalNotes" class="modal fade" tabindex="-1" aria-labelledby="modalInternalNotes" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalInternalNotes">Notes internes</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <form-textarea
                                name="notes"
                                label="Notes internes"
                                :label-hidden="false"
                                @update:field="internalNotes = $event"
                                :errors="errors"
                                :required="false"
                                :data="internalNotes"
                                bs-class="col-xl-12"
                                :row="10"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                            <button type="button" class="btn btn-secondary" @click.prevent="updateInternalNotes">Valider</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal lost reason -->
            <div id="modalReasonForLoss" class="modal fade" tabindex="-1" aria-labelledby="modalReasonForLoss" aria-hidden="true" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <loader v-if="loadingReasonForLoss"></loader>
                    <form v-on:submit.prevent="updateReasonForLoss" v-show="!loadingReasonForLoss">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalReasonForLoss">Raison de la perte</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <form-select-field
                                name="reason_for_loss"
                                label="Raison de la perte"
                                :label-hidden="false"
                                @update:field="formReasonForLoss.reason_for_loss = $event"
                                :errors="errors"
                                :required="formReasonForLoss.status === 'lost'"
                                :data="formReasonForLoss.reason_for_loss"
                                bs-class="col-xl-12"
                                :disabled="false"
                                :liste-options="[
                                    { text: 'Doublon', value: 'Doublon' },
                                    { text: 'Plus de réponse', value: 'Plus de réponse' },
                                    { text: 'Prix', value: 'Prix' },
                                    { text: 'Concurrent', value: 'Concurrent' },
                                    { text: 'Délais', value: 'Délais' },
                                    { text: 'Perte de confiance', value: 'Perte de confiance' },
                                    { text: 'Hors sujet', value: 'Hors sujet' },
                                    { text: 'Garantie', value: 'Garantie' },
                                    { text: 'Assurance', value: 'Assurance' },
                                ]"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                            <button type="submit" class="btn btn-secondary" >Valider</button>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            <!-- Modal fiche travail -->
            <div id="modalPdf" class="modal fade" tabindex="-1" aria-labelledby="modalPdf" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false" style="display: none;">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalPdf">Fiche de travail</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <!--Missing data-->
                            <div class="alert alert-danger alert-border-left alert-dismissible fade shadow show mb-3" role="alert" v-if="canGeneratePdf() !== true">
                                <p>
                                    Afin de générer la fiche de travail, l'ensemble des informations suivantes doivent être renseignées dans la demande client :
                                </p>
                                <ul>
                                    <li v-if="clientRequest.data.car_brand_id === null">Sélection de la marque dans le configurateur</li>
                                    <li v-if="clientRequest.data.car_model_id === null">Sélection du modèle dans le configurateur</li>
                                    <li v-if="clientRequest.data.car_version_id === null">Sélection de la version dans le configurateur</li>
                                    <li v-if="clientRequest.data.car_engine_id === null">Sélection du moteur dans le configurateur</li>
                                    <li v-if="clientRequest.data.vin === null">VIN</li>
                                    <li v-if="clientRequest.data.license_plate === null">Immatriculation</li>
                                    <li v-if="clientRequest.data.vehicle_year === null">Année du véhicule</li>
                                    <li v-if="clientRequest.data.mileage === null">Kilométrage</li>
                                </ul>
                                <div v-if="clientRequest.data.car_power_type === null || clientRequest.data.car_power_type === 'petrol' || clientRequest.data.car_power_type === 'hybrid_petrol'">
                                    <p>
                                        Pour les véhicules à essence :
                                    </p>
                                    <ul class="mb-3">
                                        <li>Kilométrage bougies</li>
                                        <li>Kilométrage bobines</li>
                                        <li>Indice octane</li>
                                    </ul>
                                </div>
                                <router-link :to="{ name: 'client-requests.edit', params:{ id: clientRequest.data.id } }" type="button" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light">Modifier la demande</router-link>
                            </div>
                            <!--Generated document-->
                            <div class="alert alert-info shadow" role="alert" v-if="clientRequest.data.worksheet_path !== null && clientRequest.data.worksheet_signed_path === null">
                                <p class="mb-0">
                                    Vous avez déjà généré une fiche de travail le <strong>{{ clientRequest.data.worksheet_generated_at }}</strong>.
                                    <br>Vous pouvez l'afficher en cliquant sur le bouton ci-dessous : <br>
                                    <a :href="clientRequest.data.worksheet_path" target="_blank" class="btn btn-sm btn-info bg-gradient waves-effect waves-light mt-2 align-middle me-2">
                                        <i class="mdi mdi-file-certificate-outline align-middle me-1 fs-12"></i>Afficher
                                    </a>
                                    <a href="#" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light mt-2 align-middle" v-if="clientRequest.data.worksheet_signed_path === null && !loadingSignature" @click.prevent="generateSubmission()">
                                        Signer le document
                                    </a>
                                    <button type="button" class="btn btn-sm btn-dark btn-load bg-gradient mt-2 align-middle" v-if="loadingSignature">
                                        <span class="d-flex align-items-center">
                                            <span class="flex-grow-1 me-2">
                                                Préparation...
                                            </span>
                                            <span class="spinner-border flex-shrink-0" role="status">
                                                <span class="visually-hidden">Préparation...</span>
                                            </span>
                                        </span>
                                    </button>
                                </p>
                            </div>
                            <!--Signed document and audit-->
                            <div class="alert alert-success shadow" role="alert" v-if="clientRequest.data.worksheet_signed_path !== null">
                                <p class="mb-0">
                                    La fiche de travail est signée.
                                    <br>Vous pouvez l'afficher ainsi que l'audit en cliquant sur l'un des bouton ci-dessous : <br>
                                    <a :href="clientRequest.data.worksheet_signed_path" target="_blank" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light mt-2 align-middle me-2">
                                        <i class="mdi mdi-file-certificate-outline align-middle me-1 fs-12"></i>Fiche signée
                                    </a>
                                    <a :href="clientRequest.data.worksheet_audit_path" target="_blank" class="btn btn-sm btn-dark bg-gradient waves-effect waves-light mt-2 align-middle me-2">
                                        <i class="mdi mdi-file-certificate-outline align-middle me-1 fs-12"></i>Audit
                                    </a>
                                </p>
                            </div>
                            <!--Origin reset-->
                            <div class="alert alert-warning shadow" role="alert" v-if="showOriginReset">
                                <p class="mb-0">
                                    Si vous effectuez une remise à l'origine configurée par un autre préparateur, veuillez procéder en mode bench ou boot afin d'éviter tout risque de plantage dû à un possible immo OFF.
                                </p>
                            </div>
                            <!--Form-->
                            <div v-if="clientRequest.data.worksheet_signed_path === null && !generatingPdf">
                                <form-multi-select
                                    :errors="errors"
                                    label="Prestations"
                                    name="prestations"
                                    :required="true"
                                    :data="formPdf.prestations"
                                    @update:field="formPdf.prestations = $event"
                                    bs-class="col-md-12"
                                    :disabled="false"
                                    :label-hidden="false"
                                    placeholder="Sélectionnez une ou plusieurs prestations"
                                    :liste-options="[
                                        { name: 'Stage 0', id: 'STAGE_0' },
                                        { name: 'Stage 1', id: 'STAGE_1' },
                                        { name: 'Stage 2', id: 'STAGE_2' },
                                        { name: 'Stage 3', id: 'STAGE_3' },
                                        { name: 'E85 Flex+', id: 'E85_FLEX+' },
                                        { name: 'E85 Flexfuel', id: 'E85_FLEXFUEL' },
                                        { name: 'Remise d\'origine', id: 'ORIGIN_RESET' },
                                    ]"
                                />

                                <form-multi-select
                                    :errors="errors"
                                    label="Options"
                                    name="options"
                                    :required="false"
                                    :data="formPdf.options"
                                    @update:field="formPdf.options = $event"
                                    bs-class="col-md-12"
                                    :disabled="false"
                                    :label-hidden="false"
                                    placeholder="Sélectionnez une ou plusieurs options"
                                    :liste-options="[
                                        { name: 'Banc de puissance', id: 'POWER_BENCH' },
                                        { name: 'Cold Start Off', id: 'COLD_START_OFF' },
                                        { name: 'Cylinder On Demand Off', id: 'CYLINDER_ON_DEMAND_OFF' },
                                        { name: 'DPF Off', id: 'DPF_OFF' },
                                        { name: 'DSG Fart', id: 'DSG_FART' },
                                        { name: 'DTC Off', id: 'DTC_OFF' },
                                        { name: 'Decat (Cat Off)', id: 'DECAT_CAT_OFF' },
                                        { name: 'E85 Flexfuel', id: 'E85_FLEXFUEL' },
                                        { name: 'EGR Off', id: 'EGR_OFF' },
                                        { name: 'Entretien forfait essentiel', id: 'ESSENTIAL_PLAN' },
                                        { name: 'Entretien forfait sérénité', id: 'CHILL_PLAN' },
                                        { name: 'Exhaust Flaps', id: 'EXHAUST_FLAPS' },
                                        { name: 'GPF Off', id: 'GPF_OFF' },
                                        { name: 'Gearbox', id: 'GEARBOX' },
                                        { name: 'Hard Rev Cut', id: 'HARD_REV_CUT' },
                                        { name: 'Launch Control', id: 'LAUNCH_CONTROL' },
                                        { name: 'Pop & Bang', id: 'POP_BANG' },
                                        { name: 'Pop & Bang Start Button', id: 'POP_BANG_START_BUTTON' },
                                        { name: 'Pop Corn', id: 'POP_CORN' },
                                        { name: 'PPF', id: 'PPF' },
                                        { name: 'Protection céramique', id: 'CERAMIC' },
                                        { name: 'SCR Off', id: 'SCR_OFF' },
                                        { name: 'Start & Stop Off', id: 'START_STOP_OFF' },
                                        { name: 'Swirl Flap Off', id: 'SWIRL_FLAP_OFF' },
                                        { name: 'Vmax Off', id: 'VMAX_OFF' },
                                        { name: 'Véhicule de courtoisie', id: 'CAR_BORROW' },
                                    ]"
                                />
                            </div>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                            <button type="button" class="btn btn-secondary" @click.prevent="generatePdf" :disabled="!canGeneratePdf()" v-if="!generatingPdf && clientRequest.data.worksheet_signed_path === null">{{ clientRequest.data.worksheet_generated_at !== null ? 'Regénérer' : 'Générer' }}</button>
                            <button type="button" class="btn btn-secondary btn-load"  v-if="generatingPdf && clientRequest.data.worksheet_signed_path === null">
                                <span class="d-flex align-items-center">
                                    <span class="flex-grow-1 me-2">
                                        Génération...
                                    </span>
                                    <span class="spinner-border flex-shrink-0" role="status">
                                        <span class="visually-hidden">Génération...</span>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal Signature -->
            <div id="modalSignature" class="modal fade" tabindex="-1" aria-labelledby="modalSignature" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalSignature">Signer le document</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <DocusealForm
                                :with-title="false"
                                :with-send-copy-button="false"
                                :with-download-button="false"
                                @complete="signatureCompleted"
                                language="fr"
                                v-if="slugForm !== null"
                                :src="`https://docuseal.co/s/${slugForm}`"
                            />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Modal calendar -->
            <div id="modalCalendar" class="modal fade" aria-labelledby="modalCalendar" aria-hidden="true" style="display: none;" data-bs-backdrop="static" data-bs-keyboard="false">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="modalCalendar">Fixer le RDV client</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> </button>
                        </div>
                        <div class="modal-body">
                            <form @submit.prevent="setAppointmentDate">
                                <div class="row">
                                <form-date-time-picker
                                    name="start_date"
                                    label="Date / heure début"
                                    :label-hidden="false"
                                    @update:field="formAppointment.start_date = $event"
                                    :errors="errors"
                                    :required="true"
                                    :data="formAppointment.start_date"
                                    bs-class="col-xxl-5"
                                    :disabled="false"
                                />

                                <form-date-time-picker
                                    name="end_date"
                                    label="Date / heure fin"
                                    :label-hidden="false"
                                    @update:field="formAppointment.end_date = $event"
                                    :errors="errors"
                                    :required="true"
                                    :data="formAppointment.end_date"
                                    bs-class="col-xxl-5"
                                    :disabled="formAppointment.start_date === null"
                                />

                                <google-calendar-color-selector
                                    name="google_color_id"
                                    label="Type de RDV"
                                    :color-pre-selected="preselectedColor"
                                    @update:field="formAppointment.google_color_id = $event.id"
                                    :required="true"
                                    bs-class="col-xxl-2"
                                    :errors="errors"
                                />

                                <div class="d-flex gap-2 justify-content-end">
                                    <button type="submit" class="btn btn-success">Valider</button>
                                </div>
                            </div>
                            </form>

                            <hr class="border-dashed">
                            <div class="modal-calendar">
                                <FullCalendar :options='calendarOptions' ref="calendarRef" v-if="displayCalendar"/>
                            </div>
                            <loader v-if="loadingCalendar"/>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-soft-dark fw-medium text-decoration-none shadow-none" data-bs-dismiss="modal">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            <!--Modal update client request-->
            <div class="modal fade zoomIn" id="modalUpdateClientRequest" tabindex="-1" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content" >
                        <div class="modal-header">
                            <h5 class="modal-title">
                                Modifier demande client
                            </h5>
                        </div>
                        <div class="modal-body" v-if="loadingClientRequestUpdate">
                            <loader/>
                        </div>
                        <form v-on:submit.prevent="updateClientRequest" v-if="!loadingClientRequestUpdate">
                            <div class="modal-body row">

                                <form-select-field
                                    name="status_request"
                                    label="Statut"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.status = $event"
                                    :errors="errors"
                                    :required="false"
                                    :data="formClientRequestUpdate.status"
                                    bs-class="col-xl-6"
                                    :liste-options="[
                                        { text: 'À traiter', value: 'pending' },
                                        { text: 'Attente d\'information', value: 'awaiting_information' },
                                        { text: 'Répondu', value: 'responded' },
                                        { text: 'RDV programmé', value: 'appointment_scheduled' },
                                        { text: 'RDV en cours', value: 'appointment_scheduled' },
                                        { text: 'Gagné', value: 'won' },
                                        { text: 'Client non présenté', value: 'no_show' },
                                        { text: 'Perdu', value: 'lost' },
                                        { text: 'Fermé', value: 'closed' }
                                    ]"
                                />

                                <form-select-field
                                    name="request_origin"
                                    label="Origine demande"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.request_origin = $event"
                                    :errors="errors"
                                    :required="true"
                                    :data="formClientRequestUpdate.request_origin"
                                    bs-class="col-xl-6"
                                    :disabled="false"
                                    :liste-options="[
                                        { text: 'Comptoir', value: 'Comptoir' },
                                        { text: 'Événement', value: 'Événement' },
                                        { text: 'Influenceur', value: 'Influenceur' },
                                        { text: 'Réseaux sociaux', value: 'Réseaux sociaux' },
                                        { text: 'RingOver', value: 'RingOver' },
                                        { text: 'ADS', value: 'ADS' },
                                        { text: 'Site web', value: 'Site web' },
                                        { text: 'Site web - ChatBot', value: 'Site web - ChatBot' },
                                        { text: 'Campagne SMS', value: 'Campagne SMS' },
                                        { text: 'Campagne Mail', value: 'Campagne Mail' },
                                        { text: 'Parrainage', value: 'Parrainage' },
                                        { text: 'Landing page', value: 'Landing page' },
                                    ]"
                                />

                                <form-select-field
                                    v-show="formClientRequestUpdate.status === 'lost'"
                                    name="reason_for_loss"
                                    label="Raison de la perte"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.reason_for_loss = $event"
                                    :errors="errors"
                                    :required="formClientRequestUpdate.status === 'lost'"
                                    :data="formClientRequestUpdate.reason_for_loss"
                                    bs-class="col-xl-12"
                                    :disabled="false"
                                    :liste-options="[
                                        { text: 'Doublon', value: 'Doublon' },
                                        { text: 'Plus de réponse', value: 'Plus de réponse' },
                                        { text: 'Prix', value: 'Prix' },
                                        { text: 'Concurrent', value: 'Concurrent' },
                                        { text: 'Délais', value: 'Délais' },
                                        { text: 'Perte de confiance', value: 'Perte de confiance' },
                                        { text: 'Hors sujet', value: 'Hors sujet' },
                                        { text: 'Garantie', value: 'Garantie' },
                                        { text: 'Assurance', value: 'Assurance' },
                                    ]"
                                />

                                <form-select-field
                                    name="contact_reason"
                                    label="Raison contact"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.contact_reason = $event"
                                    :errors="errors"
                                    :required="true"
                                    :data="formClientRequestUpdate.contact_reason"
                                    bs-class="col-xl-6"
                                    :liste-options="[
                                        { text: 'Rendez-vous', value: 'Rendez-vous' },
                                        { text: 'Demande de rappel', value: 'Demande de rappel' },
                                        { text: 'Devis', value: 'Devis' },
                                        { text: 'Demande d’information', value: 'Demande d’information' },
                                        { text: 'Devenir franchisé', value: 'Devenir franchisé' },
                                        { text: 'Reprog. en Attente', value: 'Reprog. en Attente' },
                                        { text: 'Participer au Dev.', value: 'Participer au Dev.' },
                                        { text: 'Assistance', value: 'Assistance' },
                                        { text: 'JOBS', value: 'JOBS' },
                                        { text: 'Autre', value: 'Autre' }
                                    ]"
                                />

                                <form-select-field
                                    name="car_stage"
                                    label="Prestation souhaitée"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.car_stage = $event"
                                    :errors="errors"
                                    :required="true"
                                    :data="formClientRequestUpdate.car_stage"
                                    bs-class="col-xl-6"
                                    :liste-options="[
                                            { text: 'Reprogrammation stage 0', value: 'Reprogrammation stage 0' },
                                            { text: 'Reprogrammation stage 1', value: 'Reprogrammation stage 1' },
                                            { text: 'Reprogrammation stage 2', value: 'Reprogrammation stage 2' },
                                            { text: 'Reprogrammation stage 3', value: 'Reprogrammation stage 3' },
                                            { text: 'Boite de vitesse', value: 'Boite de vitesse' },
                                            { text: 'Conversion E85', value: 'Conversion E85' },
                                            { text: 'Conversion E85+', value: 'Conversion E85+' },
                                            { text: 'Pièces performance', value: 'Pièces performance' },
                                            { text: 'Entretien', value: 'Entretien' },
                                            { text: 'Passage au banc', value: 'Passage au banc' },
                                            { text: 'Esthétique', value: 'Esthétique' },
                                            { text: 'Diagnostique', value: 'Diagnostique' },
                                        ]"
                                />

                                <form-multi-select
                                    :errors="errors"
                                    label="Tags"
                                    name="tags"
                                    :required="false"
                                    :data="formClientRequestUpdate.tags"
                                    @update:field="formClientRequestUpdate.tags = $event"
                                    bs-class="col-md-12"
                                    :disabled="false"
                                    :label-hidden="false"
                                    placeholder="Sélectionnez un ou plusieurs tags"
                                    :options-pre-selected="tagsPreSelected"
                                    :liste-options="[
                                            { name: 'Promotion', id: 'Promotion' },
                                            { name: 'ECU en dev', id: 'ECU en dev' },
                                            { name: 'SAV', id: 'SAV' },
                                            { name: 'Remise Prépa', id: 'Remise Prépa' },
                                            { name: 'Remise origine', id: 'Remise origine' },
                                            { name: 'Franchise', id: 'Franchise' },
                                            { name: 'Jobs', id: 'Jobs' },
                                            { name: 'Jeux-concours', id: 'Jeux-concours' },
                                            { name: 'Doublon', id: 'Doublon' },
                                        ]"
                                />

                                <form-switch
                                    name="is_on_promotion"
                                    label="Est en promotion ?"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.is_on_promotion = $event"
                                    :errors="errors"
                                    :required="false"
                                    :data="formClientRequestUpdate.is_on_promotion"
                                    bs-class="col-md-12"
                                    :disabled="false"
                                />

                                <form-select-field
                                    name="vehicle_type"
                                    label="Type véhicule"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.vehicle_type = $event"
                                    :errors="errors"
                                    :required="false"
                                    :data="formClientRequestUpdate.vehicle_type"
                                    bs-class="col-xl-6"
                                    :disabled="false"
                                    :placeholder="formClientRequestUpdate.vehicle_type === null ? 'Sélectionnez un type'  : null"
                                    :liste-options="[
                                            { text: 'Voiture', value: 'car' },
                                            { text: 'Agricole', value: 'agri' },
                                            { text: 'ATV', value: 'atv' },
                                            { text: 'Camion', value: 'trucks' },
                                            { text: 'Jetski', value: 'jetski' },
                                            { text: 'Moto', value: 'moto' },
                                            { text: 'Réinitialiser configurateur', value: null },
                                        ]"
                                />

                                <form-select-field
                                    name="car_brand_id"
                                    label="Marques"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.car_brand_id = $event"
                                    :errors="errors"
                                    :required="false"
                                    :data="formClientRequestUpdate.car_brand_id"
                                    bs-class="col-xl-6"
                                    :liste-options="carBrands"
                                    :placeholder="formClientRequestUpdate.vehicle_type === null ? 'Sélectionnez un type' : 'Toutes les marques'"
                                    :disabled="formClientRequestUpdate.vehicle_type === null"
                                />

                                <form-select-field
                                    name="car_model_id"
                                    label="Modèles"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.car_model_id = $event"
                                    :errors="errors"
                                    :required="false"
                                    :data="formClientRequestUpdate.car_model_id"
                                    bs-class="col-xl-6"
                                    :disabled="formClientRequestUpdate.car_brand_id === null"
                                    :liste-options="carModels"
                                    :placeholder="formClientRequestUpdate.car_brand_id === null ? 'Sélectionnez une marque' : 'Tous les modèles'"
                                />

                                <form-select-field
                                    name="car_version_id"
                                    label="Versions"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.car_version_id = $event"
                                    :errors="errors"
                                    :required="false"
                                    :data="formClientRequestUpdate.car_version_id"
                                    bs-class="col-xl-6"
                                    :disabled="formClientRequestUpdate.car_model_id === null"
                                    :liste-options="carVersions"
                                    :placeholder="formClientRequestUpdate.car_model_id === null ? 'Sélectionnez un modèle' : 'Toutes les versions'"
                                />

                                <form-select-field
                                    name="car_engine_id"
                                    label="Motorisation"
                                    :label-hidden="false"
                                    @update:field="formClientRequestUpdate.car_engine_id = $event"
                                    :errors="errors"
                                    :required="false"
                                    :data="formClientRequestUpdate.car_engine_id"
                                    bs-class="col-xl-12"
                                    :disabled="formClientRequestUpdate.car_version_id === null"
                                    :liste-options="carEngines"
                                    :placeholder="formClientRequestUpdate.car_engine_id === null ? 'Sélectionnez un moteur' : 'Tous les moteurs'"
                                    :margin="formClientRequestUpdate.car_engine_id === null ? 'mb-3' : 'mb-2'"
                                />

                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-soft-dark" data-bs-dismiss="modal">Fermer</button>
                                <button type="submit" class="btn btn-secondary">Valider</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6" v-if="!loading">
            <Chat
                :messageable-id="parseInt(clientRequest.data.id)"
                messageable-type="App\Models\ClientRequest"
                :show-is-internal="false"
                :show-default-messages="true"
                :default-message-center-id="parseInt(clientRequest.data.center_id)"
            />
        </div>
    </div>
</template>

<style scoped>
.modal-calendar{
    height: 70vh !important;
}
@media (min-width: 576px) {
    .modal-xl {
        --vz-modal-width: 90% !important;
    }
}
</style>
